import Typography from "@mui/material/Typography";
import Stack from "@mui/system/Stack";

export default function Custom404() {
  return (
    <Stack height="90vh" alignItems="center" justifyContent="center">
      <Typography>404: 존재하지 않는 페이지입니다.</Typography>
    </Stack>
  );
}
